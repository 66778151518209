body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face { font-family: "Matthan"; src: url("/static/media/matthan sans regular.4c383208.ttf") format("truetype"); font-display: block; }

@font-face { font-family: "Notosans"; src: url(/static/media/NotoSans-Regular.5a87cce8.ttf) format("truetype"); font-display: block; }

@font-face { font-family: "Secular"; src: url(/static/media/SecularOne-Regular.be1378e6.ttf) format("truetype"); font-display: block; }

@font-face { font-family: "Overpass"; src: url(/static/media/Overpass-Regular.0807de33.ttf) format("truetype"); font-display: block; }

@font-face { font-family: "Overpass-Bold"; src: url(/static/media/Overpass-ExtraBold.fe565f96.ttf) format("truetype"); font-display: block; }

::-webkit-scrollbar { width: 15px; }

/* Track */
::-webkit-scrollbar-track { box-shadow: inset 0 0 5px #323232; border-radius: 10px; }

::-webkit-scrollbar-track-piece { background: #000; }

/* Handle */
::-webkit-scrollbar-thumb { background: #323232; border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #323232; }

::selection { background: #000; /* WebKit/Blink Browsers */ color: #fff; }

::-moz-selection { background: #fff; /* Gecko Browsers */ color: #000; }

@font-face { font-family: "Matthan"; src: url("/static/media/matthan sans regular.4c383208.ttf") format("truetype"); font-display: block; }

@font-face { font-family: "Notosans"; src: url(/static/media/NotoSans-Regular.5a87cce8.ttf) format("truetype"); font-display: block; }

@font-face { font-family: "Secular"; src: url(/static/media/SecularOne-Regular.be1378e6.ttf) format("truetype"); font-display: block; }

@font-face { font-family: "Overpass"; src: url(/static/media/Overpass-Regular.0807de33.ttf) format("truetype"); font-display: block; }

@font-face { font-family: "Overpass-Bold"; src: url(/static/media/Overpass-ExtraBold.fe565f96.ttf) format("truetype"); font-display: block; }

.swiper-pagination-bullet-active { background-color: #000; }

.product-details { position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%) !important; transform: translate(-50%, -50%) !important; }

.about-img { max-height: 50vh; object-fit: cover; position: absolute; z-index: -1; -webkit-filter: brightness(75%); filter: brightness(75%); }

.about-content { min-height: 40vh; }

img.full-width { width: 100%; height: 400px; object-fit: cover; }

.about p { text-align: justify; }

.highlight-text-cont { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); background-color: #fff; width: 175px; height: 175px; }

@media screen and (max-width: 768px) { .highlight-text-cont { width: 100px; height: 100px; } }

.highlight:hover .highlight-text-cont .highlight-text .border-line, .highlight:active .highlight-text-cont .highlight-text .border-line { width: 100%; }

.highlight-text { font-size: 1.5vmin; max-width: 80%; max-height: 80%; transition: 0.4s; text-align: left; }

@media screen and (max-width: 768px) { .highlight-text { font-size: 1vmax; } }

.highlight-text div { display: block; width: 100%; }

.border { text-align: left; width: 30% !important; margin: 15px; margin-right: 50%; height: 3px; background: black; border: 0px none !important; }

@media screen and (max-width: 768px) { .border { margin-right: 80%; } }

.highlight a { text-decoration: none; color: #000; }

.highlight a:hover { color: #000; }

.highlight img { width: 100%; height: 100%; object-fit: cover; }

.highlight-who { z-index: 5; width: auto; position: absolute; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); top: 15%; left: 50%; }

@media screen and (max-width: 768px) { .highlight-who { width: 75%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); top: 50%; left: 50%; } }

.highlight-about { background-image: url(/static/media/image0.b0a7e521.jpeg); }

.highlight-store { background-image: url(/static/media/image0.b0a7e521.jpeg); }

.highlight-blog { background-image: url(/static/media/image0.b0a7e521.jpeg); }

.about-overlap { height: 60vh; position: relative; }

.overlap { background-position: center; background-repeat: no-repeat; background-size: cover; transition: 0.4s; background-origin: content-box; }

.overlap.top { position: absolute; width: 40%; height: 75%; bottom: 0; right: 5%; z-index: 2; }

@media screen and (max-width: 768px) { .overlap.top { width: 50%; } }

.overlap.bottom { position: absolute; width: 50%; height: 75%; bottom: 20%; right: 25%; z-index: 1; }

@media screen and (max-width: 768px) { .overlap.bottom { width: 65%; } }

.overlap.text { position: absolute; left: 5%; bottom: 10%; width: 40%; display: flex; justify-content: center; align-items: center; z-index: 2; }

@media screen and (max-width: 768px) { .overlap.text { left: 3%; } }

.border-text { width: 80%; font-size: 1.5vmin; }

@media screen and (max-width: 768px) { .border-text { font-size: 1.5vmax; } }

.home-grid-cont { position: relative; }

.home-grid-cont .home-grid { height: 100vh !important; -webkit-filter: brightness(0.5); filter: brightness(0.5); overflow: hidden; /* 2 columns wide */ }

.home-grid-cont .home-grid .grid-sizer, .home-grid-cont .home-grid .grid-item { width: 25%; }

@media screen and (max-width: 768px) { .home-grid-cont .home-grid .grid-sizer, .home-grid-cont .home-grid .grid-item { width: 50%; } }

.home-grid-cont .home-grid .grid-sizer img, .home-grid-cont .home-grid .grid-item img { width: 100%; }

.home-grid-cont .home-grid .grid-item--width2 { width: 50%; }

@media screen and (max-width: 768px) { .home-grid-cont .home-grid .grid-item--width2 { width: 50%; } }

.home-grid-cont .home-logo { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); max-width: 80%; }

body { margin: 0; overflow-x: hidden; background-color: #fff; }

form { -webkit-margin-after: 0em; margin-block-end: 0em; }

p.background { background: white; /* Fallback for older browsers without RGBA-support */ background: rgba(255, 255, 255, 0.5); }

a { color: #fff; text-decoration: none; }

a:hover { color: #b2b2b2; }

.primary-color { background-color: #000 !important; color: #fff !important; }

.primary-color-light { background-color: #323232 !important; color: #fff !important; }

.primary-color-text { color: #000 !important; }

.primary-color-light-text { color: #323232 !important; }

a.primary-color-text:hover { color: #000 !important; }

.secondary-color { background-color: #fff !important; color: #000 !important; }

.secondary-color-text { color: #fff !important; }

.white { background-color: white; }

.round-corner { border-radius: 25px; }

.full-width { min-width: 100%; width: 100%; }

.crop-width { width: 100%; max-width: 1200px; }

p.crop-width { max-width: 60%; }

@media screen and (max-width: 768px) { p.crop-width { max-width: 100%; } }

ul.crop-width { max-width: 50%; }

@media screen and (max-width: 768px) { ul.crop-width { max-width: 100%; } }

.testimonials.crop-width { max-width: 50%; }

@media screen and (max-width: 768px) { .testimonials.crop-width { max-width: 100%; } }

.full-height { min-height: 100%; height: 100%; }

.height-sm { height: 25vh; }

.auto-height { height: auto; }

.no-padding { padding: 0 !important; margin: 0 !important; }

.swiper-container { width: 100%; height: 30vh; }

@media screen and (max-width: 768px) { .swiper-container { height: 70vh; } }

.swiper-slide { padding: 0px 20px 0px 20px; width: 100% !important; }

.swiper-slide p, .swiper-slide h3 { text-align: center; }

.swiper-button-next, .swiper-button-prev { color: #000 !important; }

.swiper-pagination-bullet-active { background-color: #000 !important; }

.input-group-text { display: flex; justify-content: center; width: 38px; background-color: #fff; border: 1px solid #b2b2b2; }

.input-group-text i { color: #000; }

.snackbar { display: none; transition: 0.5; min-width: 250px; -webkit-transform: translate(-50%, 0); transform: translate(-50%, 0); left: 50%; background-color: #b2b2b2; color: #000; text-align: center; border-radius: 5px; position: fixed; z-index: 10000; bottom: 30px; opacity: 0.8; }

.background-img { background-position: center; background-repeat: no-repeat; background-size: contain; position: relative; transition: 0.4s; background-origin: content-box; }

.service-text { width: 100%; display: flex; justify-content: center; flex-direction: column; }

.highlight { /* Position and center the image to scale nicely on all screens */ width: 100%; height: 400px; background-position: center; background-repeat: no-repeat; background-size: cover; position: relative; transition: 0.4s; background-origin: content-box; }

.highlight a { width: 100%; }

@media screen and (max-width: 768px) { .highlight { height: 300px; } }

.highlight-lg { height: 80vh; }

.content { position: relative; z-index: 3; box-shadow: 0px 0px 20px #ccc, 0px 0px -20px #ccc; }

.btn { background-color: #fff; border-color: #b2b2b2; border-radius: 0px; width: 100px; }

@media screen and (max-width: 768px) { .btn { width: 100%; } }

.btn:hover { background-color: #b2b2b2; }

.btn-submit { margin-bottom: 0px !important; }

.footer { width: 100%; position: relative; bottom: 0; z-index: 2; }

.footer i { transition: 0.5s; }

.footer-insta:hover { color: #cf426d !important; }

.footer-facebook:hover { color: #3877ea !important; }

.logo { position: absolute; top: 25; left: 25; }

@media screen and (max-width: 768px) { .logo { left: 10; top: 10; } }

.logo img { max-height: 100px; object-fit: cover; }

@media screen and (max-width: 768px) { .logo img { max-height: 75px; } }

hr.border-line { text-align: left; margin: 0 auto 0 0; color: #000; background-color: #000; height: 5px; width: 40%; margin-top: 10px; margin-bottom: 10px; transition: 0.4s; }

hr.border-line-header { text-align: center; margin: 0 auto; color: #000; background-color: #000; height: 5px; width: 10%; margin-top: 10px; margin-bottom: 10px; transition: 0.4s; }

hr.border-line.full { width: 100%; }

.service-text hr.border-line { background-color: #fff; }

.notosans { font-family: "Notosans"; }

.matthan { font-family: "Matthan"; letter-spacing: 2px; }

.secular { font-family: "Secular"; }

.overpass { font-family: "Overpass"; }

.overpass-bold { font-family: "Overpass-Bold"; }

.xsmall { font-size: 0.75vmax; }

.small { font-size: 1vmax; }

.smallmed { font-size: 1.5vmax; }

.medium { font-size: 2vmax; }

.medium-h { font-size: 2vh; }

.large { font-size: 3vmax; }

.xlarge { font-size: 4vmax; }

.opacity90 { opacity: 0.9; }

.opacity50 { background: white; /* Fallback for older browsers without RGBA-support */ background: rgba(255, 255, 255, 0.5); }

.header { height: 50vh; position: relative; justify-content: center; align-items: center; flex-direction: column; }

.header .header-overlay { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); display: flex; flex-direction: column; justify-content: center; align-items: center; }

.header .header-overlay img { max-height: 250px; }

.header-sm { height: 60vh; position: relative; justify-content: center; align-items: center; flex-direction: column; }

@media screen and (max-width: 768px) { .header-sm { height: 100vh; } }

.center { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); }

a.nav-link { text-align: center; }

a.nav-link:hover { text-decoration: underline; }

.nav-item button { background: transparent; border: none; }

.sticky { position: -webkit-sticky !important; position: sticky !important; }

.navbar { transition: 0.4s; top: 0; width: 100%; position: fixed; z-index: 5; font-size: 1.5vh; }

@media screen and (max-width: 768px) { .navbar { font-size: 3vh; } }

.navbar-collapse.show { transition: 0.4s; background-color: #fff; }

.transparent { background: transparent !important; }

.navbar .nav-link { color: #fff; transition: 0.4s; }

@media screen and (max-width: 992px) { .navbar .nav-link { color: #000; } }

.navbar-nav { background-color: #fff; }

@media (min-width: 992px) { .navbar-nav { background-color: transparent; } }

.icon-bar { width: 22px; height: 2px; background-color: #000; display: block; transition: all 0.2s; margin-top: 4px; }

.navbar-toggler { border: none; background: transparent !important; }

.navbar-toggler .top-bar { -webkit-transform: rotate(45deg); transform: rotate(45deg); -webkit-transform-origin: 10% 10%; transform-origin: 10% 10%; }

.navbar-toggler .middle-bar { opacity: 0; }

.navbar-toggler .bottom-bar { -webkit-transform: rotate(-45deg); transform: rotate(-45deg); -webkit-transform-origin: 10% 90%; transform-origin: 10% 90%; }

.navbar-toggler.collapsed .top-bar { -webkit-transform: rotate(0); transform: rotate(0); }

.navbar-toggler.collapsed .middle-bar { opacity: 1; }

.navbar-toggler.collapsed .bottom-bar { -webkit-transform: rotate(0); transform: rotate(0); }

.dropdown-menu { background-color: #000; }

.dropdown-item { color: #fff; }

.dropdown-item:hover { background-color: #fff; color: #000 !important; }

.is-inline .shopify-buy__cart-toggle__count { margin-left: 0px; }

.is-inline .shopify-buy__cart-toggle__count:before { background-color: transparent; }

.shopify-buy__cart-toggle { background-color: #000; color: #323232; }

.shopify-buy__cart-toggle:hover { background-color: #000; }

.shopify-buy__cart-toggle__count { margin-bottom: 0; margin-right: 10px; }

.shopify-buy__btn { background-color: #000 !important; color: #fff !important; padding: 12px 12px; width: calc(100% - 24px); }

.shopify-buy__btn:hover { background-color: #323232 !important; }

.shopify-buy__btn-wrapper { height: 40px; margin-top: auto; }

.shopify-buy__product__variant-img { /*padding-left: 5px; padding-right: 5px; width: 100%; height: 35vh; object-fit: cover; object-position: center;*/ object-fit: cover; width: 100%; }

.shopify-buy__product { display: flex; flex-direction: column; width: 100%; }

.shopify-buy-frame--toggle { margin-right: 0 !important; }

.gallery-img { cursor: pointer; }

.modal { display: none; position: fixed; z-index: 10; padding-top: 100px; left: 0; top: 0; width: 100%; height: 100%; overflow: auto; background-color: black; background-color: rgba(0, 0, 0, 0.8); }

.modal-img { margin: auto; display: block; width: 80%; max-width: 700px; z-index: 11; }

@media only screen and (max-width: 700px) { .modal-img { width: 100%; } }

.gallery-grid img { padding: 8px; }

.spinner { margin: 100px auto 0; width: 70px; text-align: center; }

.spinner > div { width: 18px; height: 18px; background-color: #fff; border-radius: 100%; display: inline-block; -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both; animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner .bounce1 { -webkit-animation-delay: -0.32s; animation-delay: -0.32s; }

.spinner .bounce2 { -webkit-animation-delay: -0.16s; animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay { 0%, 80%, 100% { -webkit-transform: scale(0); }
  40% { -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay { 0%, 80%, 100% { -webkit-transform: scale(0);
    transform: scale(0); }
  40% { -webkit-transform: scale(1);
    transform: scale(1); } }

.jumbotron { background-color: #323232 !important; }

.dismissible { width: 100%; margin: 0 auto; display: flex; color: #222; font-size: 16px; line-height: 22px; position: fixed; bottom: 20px; z-index: 9001; overflow: hidden; }

.dismissible span { width: 80%; background: #fff; border: 2px solid rgba(0, 0, 0, 0.3); border-radius: 5px; box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); box-sizing: border-box; display: block; margin: 0 auto 20px auto; padding: 10px; position: relative; }

.dismissible span p { padding-right: 25px; }

.dismissible button { background: rgba(0, 0, 0, 0.4); border: 0; border-radius: 100%; color: #fff; cursor: pointer; font-size: 14px; font-weight: bold; height: 24px; line-height: 20px; margin-top: -12px; right: 10px; position: absolute; top: 50%; width: 24px; }

.dismissible button:focus { outline: none; }

.dismissible-error span { background-color: #ff5252; border-color: #ff1744; color: #fff; }

.dismissible-error button { background-color: #d50000; color: #ffcdd2; }

.dismissible-info span { background-color: #000; border-color: #000; color: #fff; }

.dismissible-info button { background-color: #000; color: #fff; }

.dismissible-success span { background-color: #9ccc65; border-color: #7cb342; color: #fff; }

.dismissible-success button { background-color: #7cb342; color: #dcedc8; }

.dismissible-warning span { background-color: #ffa726; border-color: #fb8c00; color: #fff; }

.dismissible-warning button { background-color: #fb8c00; color: #ffe0b2; }

html, body, h1, h2, h3, h4, h5 { padding: 0; margin: 0; }

* { box-sizing: border-box; }

body, html { min-height: 100%; }

html { font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 14px; line-height: 1.2; color: #4c4c4c; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

select { text-rendering: auto !important; }

ul { list-style: none; padding-left: 0; margin: 0; }

img { display: block; max-width: 100%; }

input { -webkit-appearance: textfield; margin: 0; }

.clearfix:after { content: ""; display: table; clear: both; }

.visuallyhidden { border: 0; height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

.component-container { overflow: hidden; }

.shopify-buy__type--center { text-align: center; }

.shopify-buy--visually-hidden { position: absolute !important; clip: rect(1px, 1px, 1px, 1px); padding: 0 !important; border: 0 !important; height: 1px !important; width: 1px !important; overflow: hidden; }

.shopify-buy__btn { color: #fff; font-size: 15px; padding: 12px 40px; letter-spacing: 0.3px; display: block; border-radius: 3px; cursor: pointer; transition: background 200ms ease; max-width: 100%; text-overflow: ellipsis; overflow: hidden; line-height: 1.2; border: 0; -moz-appearance: none; -webkit-appearance: none; }

.shopify-buy__btn:hover, .shopify-buy__btn:focus { background-color: #5f9d3e; }

.shopify-buy__btn--parent { background-color: transparent; border: 0; padding: 0; cursor: pointer; }

.shopify-buy__btn--parent:hover .product__variant-img, .shopify-buy__btn--parent:focus .product__variant-img { opacity: 0.7; }

.shopify-buy__btn--cart-tab { padding: 5px 11px; border-radius: 3px 0 0 3px; position: fixed; right: 0; top: 50%; -webkit-transform: translate(100%, -50%); transform: translate(100%, -50%); opacity: 0; min-width: inherit; width: auto; height: auto; z-index: 2147483647; }

.shopify-buy__btn--cart-tab.is-active { -webkit-transform: translateY(-50%); transform: translateY(-50%); opacity: 1; }

.shopify-buy__btn__counter { display: block; margin: 0 auto 10px auto; font-size: 18px; }

.shopify-buy__icon-cart--side { height: 20px; width: 20px; }

.shopify-buy__btn[disabled] { background-color: #999; pointer-events: none; }

.shopify-buy__btn--close { position: absolute; right: 9px; top: 8px; font-size: 35px; color: #767676; border: none; background-color: transparent; transition: color 100ms ease, -webkit-transform 100ms ease; transition: transform 100ms ease, color 100ms ease; transition: transform 100ms ease, color 100ms ease, -webkit-transform 100ms ease; cursor: pointer; font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; padding-right: 9px; }

.shopify-buy__btn--close:hover { -webkit-transform: scale(1.2); transform: scale(1.2); color: dimgray; }

.shopify-buy__quantity-decrement, .shopify-buy__quantity-increment { color: #4c4c4c; display: block; height: 30px; float: left; line-height: 16px; font-family: monospace; width: 26px; padding: 0; border: none; background: transparent; box-shadow: none; cursor: pointer; font-size: 18px; text-align: center; font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; border: 1px solid #767676; position: relative; }

.shopify-buy__quantity-decrement svg, .shopify-buy__quantity-increment svg { width: 14px; height: 14px; position: absolute; top: 50%; left: 50%; margin-top: -6px; margin-left: -7px; fill: currentColor; }

.shopify-buy__quantity-decrement { border-radius: 3px 0 0 3px; }

.shopify-buy__quantity-increment { border-radius: 0 3px 3px 0; }

.shopify-buy__quantity { color: black; width: 45px; height: 30px; font-size: 16px; border: none; text-align: center; -webkit-appearance: none; -moz-appearance: textfield; display: inline-block; padding: 0; border-radius: 0; border-top: 1px solid #767676; border-bottom: 1px solid #767676; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }

.shopify-buy__quantity-container.shopify-buy__quantity-with-btns { overflow: hidden; }

.shopify-buy__quantity-container.shopify-buy__quantity-with-btns .shopify-buy__quantity { border-left: 0; border-right: 0; float: left; }

.shopify-buy__option-select-wrapper { border: 1px solid #d3dbe2; border-radius: 3px; box-sizing: border-box; position: relative; background: #fff; overflow: hidden; vertical-align: bottom; }

.shopify-buy__select-icon { cursor: pointer; display: block; fill: #798c9c; position: absolute; right: 10px; top: 50%; margin-top: -6px; pointer-events: none; width: 12px; height: 12px; }

.shopify-buy__option-select + .shopify-buy__option-select { margin-top: 7.5px; }

.shopify-buy__option-select__label { display: block; font-size: 14px; margin-top: 15px; margin-bottom: 5px; }

.shopify-buy__btn--parent .shopify-buy__option-select__label { cursor: pointer; }

.shopify-buy__option-select__select { font-size: inherit; padding: 7px 10px; padding-right: 32px; border: 0; width: 100%; background: transparent; -webkit-appearance: none; -moz-appearance: none; }

.shopify-buy__option-select__select::-ms-expand { display: none; }

.shopify-buy__btn--parent .shopify-buy__option-select__select { cursor: pointer; }

.shopify-buy__product { overflow: hidden; width: 100%; }

.shopify-buy__product__variant-img { margin: 0 auto 15px auto; transition: opacity 0.3s ease; opacity: 1; }

.shopify-buy__product__variant-img.is-transitioning { opacity: 0; }

.shopify-buy__is-button { cursor: pointer; }

.shopify-buy__no-image .shopify-buy__product__variant-img { display: none; }

.shopify-buy__product__title { font-size: 18px; line-height: 1.2; color: #4a4a4a; margin-bottom: 15px; font-weight: 700; }

.shopify-buy__layout-horizontal .shopify-buy__product__title { margin-top: 10px; }

.shopify-buy__product__variant-title { font-size: 18px; color: #666; font-weight: 400; text-align: center; margin-bottom: 15px; }

.shopify-buy__product__price { margin-bottom: 15px; }

.shopify-buy__product-description { margin-top: 30px; line-height: 1.65; color: #4a4a4a; }

.shopify-buy__product-description p, .shopify-buy__product-description ul, .shopify-buy__product-description ol, .shopify-buy__product-description img { margin-bottom: 10px; }

.shopify-buy__product-description p:last-child, .shopify-buy__product-description ul:last-child, .shopify-buy__product-description ol:last-child, .shopify-buy__product-description img:last-child { margin-bottom: 0; }

.shopify-buy__product-description a { color: inherit; }

.shopify-buy__product-description img { max-width: 100%; }

.shopify-buy__product-description h1 { font-size: 20px; }

.shopify-buy__product-description h2 { font-size: 18px; }

.shopify-buy__product-description h3 { font-size: 17px; }

.shopify-buy__product-description ul, .shopify-buy__product-description ol { margin-left: 2em; }

.shopify-buy__product-description ul { list-style-type: disc; }

.shopify-buy__layout-vertical { text-align: center; }

.shopify-buy__product__actual-price, .shopify-buy__product__compare-price { color: #4a4a4a; display: inline-block; }

.shopify-buy__product__actual-price { font-size: 14px; }

.shopify-buy__product__compare-price { font-size: 12px; text-decoration: line-through; padding-left: 5px; opacity: 0.65; }

.shopify-buy__product__unit-price { color: #4a4a4a; padding-top: 5px; font-size: 12px; opacity: 0.8; }

.shopify-buy__product__variant-selectors { text-align: left; font-size: 14px; }

.shopify-buy__layout-vertical .shopify-buy__product__variant-selectors { width: 100%; max-width: 280px; display: inline-block; }

.shopify-buy__quantity { border-left: 1px solid; border-right: 1px solid; border-radius: 3px; }

.shopify-buy__quantity, .shopify-buy__quantity-increment, .shopify-buy__quantity-decrement { border-color: #d3dbe2; line-height: 1.2; font-size: 15px; height: auto; padding-top: 12px; padding-bottom: 12px; }

.shopify-buy__btn { display: inline-block; }

.shopify-buy__btn-wrapper { margin-top: 20px; }

.shopify-buy__btn.shopify-buy__beside-quantity { display: inline-block; vertical-align: top; border-top-left-radius: 0; border-bottom-left-radius: 0; border: 1px solid transparent; }

.shopify-buy__btn-and-quantity .shopify-buy__quantity { border-right: 0; border-top-right-radius: 0; border-bottom-right-radius: 0; background: #fff; }

.shopify-buy__btn-and-quantity .shopify-buy__quantity-container { display: inline-block; vertical-align: top; }

.shopify-buy__btn-and-quantity .shopify-buy__btn-wrapper { display: inline-block; vertical-align: top; margin: 0; }

.shopify-buy__cart-item__quantity-container { margin-top: 20px; display: inline-block; }

.shopify-buy__layout-vertical .shopify-buy__btn, .shopify-buy__layout-vertical .shopify-buy__quantity-container, .shopify-buy__layout-horizontal .shopify-buy__btn, .shopify-buy__layout-horizontal .shopify-buy__quantity-container { margin: 20px auto 0; }

.shopify-buy__layout-vertical .shopify-buy__btn:first-child, .shopify-buy__layout-horizontal .shopify-buy__btn:first-child { margin-top: 0; }

.shopify-buy__layout-vertical .shopify-buy__btn-and-quantity, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity { margin: 20px auto 0; }

.shopify-buy__layout-vertical .shopify-buy__btn-and-quantity .shopify-buy__btn, .shopify-buy__layout-vertical .shopify-buy__btn-and-quantity .shopify-buy__quantity-container, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity .shopify-buy__btn, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity .shopify-buy__quantity-container { margin: 0 auto; }

.shopify-buy__layout-vertical .shopify-buy__btn-and-quantity:first-child, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity:first-child { margin: 0 auto; }

.shopify-buy__layout-vertical .shopify-buy__product__variant-img, .shopify-buy__layout-horizontal .shopify-buy__product__variant-img { max-width: 100%; }

@media (min-width: 500px) { .shopify-buy__layout-horizontal:not(.no-image) { text-align: left; margin-bottom: 0; margin-left: 0; }
  .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-img-wrapper { float: left; width: 40%; }
  .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title { text-align: left; }
  .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__price, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-description, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__btn-and-quantity, .shopify-buy__layout-horizontal:not(.no-image) > .shopify-buy__btn-wrapper, .shopify-buy__layout-horizontal:not(.no-image) > .shopify-buy__quantity-container, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-selectors { margin-left: calc(40% + 25px); } }

@media (min-width: 680px) { .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-img-wrapper { float: left; width: 60%; }
  .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__price, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-description, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__btn-and-quantity, .shopify-buy__layout-horizontal:not(.no-image) > .shopify-buy__btn-wrapper, .shopify-buy__layout-horizontal:not(.no-image) > .shopify-buy__quantity-container, .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-selectors { margin-left: calc(60% + 25px); } }

.no-image .shopify-buy__product-img-wrapper { display: none; }

@-webkit-keyframes dash { to { stroke-dashoffset: 0; } }

@keyframes dash { to { stroke-dashoffset: 0; } }

.shopify-buy__carousel { font-size: 0; text-align: center; min-height: 90px; margin-left: -15px; margin-top: 15px; }

.shopify-buy__carousel-item { width: calc(16.666% - 15px); margin-left: 15px; display: inline-block; vertical-align: middle; cursor: pointer; position: relative; background-size: cover; background-position: center; padding: 0; border: none; }

.shopify-buy__carousel-item:nth-child(n + 7) { margin-top: 15px; }

.shopify-buy__carousel-item:before { content: ""; display: block; padding-top: 100%; }

.main-image-wrapper { position: relative; }

.carousel-button { position: absolute; width: 75px; top: 0; height: 100%; border: none; font-size: 0; background-color: transparent; opacity: 0.4; cursor: pointer; }

.carousel-button:hover, .carousel-button:focus { opacity: 0.9; outline: none; }

.carousel-button-arrow { width: 20px; display: inline-block; margin-left: 25px; }

.carousel-button--previous { left: 0; -webkit-transform: rotate(180deg); transform: rotate(180deg); }

.carousel-button--next { right: 0; }

.shopify-buy__carousel-item--selected { opacity: 0.4; }

.shopify-buy__btn--close { right: 0px; font-size: 45px; font-weight: 100; z-index: 2147483647; padding: 0 10px; }

.shopify-buy__modal { background: #fff; width: calc(100% - 20px); position: absolute; left: 0; right: 0; z-index: 2147483646; }

.shopify-buy__product { text-align: left; }

.shopify-buy__product__title, .shopify-buy__product__price, .shopify-buy__product__variant-title { text-align: left; }

.shopify-buy__product__title { font-size: 26px; font-weight: 700; line-height: 1.4; }

.shopify-buy__product__compare-price { display: inline-block; margin-right: 5px; }

.shopify-buy__product__actual-price { display: inline-block; }

.shopify-buy__modal .shopify-buy__modal-product-wrapper { width: 100%; }

.shopify-buy__product__variant-image { margin: 0; }

@media (max-width: 499px) { body.is-active { overflow: hidden; position: fixed; height: 100vh; transition: all 0s; }
  .shopify-buy__modal { width: 100%; min-height: 100vh; position: fixed; overflow-y: auto; }
  .shopify-buy__product { padding: 15px; position: absolute; top: 0; left: 0; }
  .shopify-buy__product__variant-img { max-height: 60vh; margin: 0 auto; width: auto; max-width: 100%; }
  .shopify-buy__btn--close { position: fixed; top: 0; right: 0; } }

@-webkit-keyframes slideIn { from { opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px); }
  to { opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0); } }

@keyframes slideIn { from { opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px); }
  to { opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0); } }

@-webkit-keyframes slideOut { from { opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0); }
  to { opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px); } }

@keyframes slideOut { from { opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0); }
  to { opacity: 0;
    transform: translateY(-200px);
    -webkit-transform: translateY(-200px); } }

@media (min-width: 500px) { html, body.is-active { height: 100%; }
  .shopify-buy__modal-overlay { width: 100%; height: 100%; position: fixed; overflow-y: scroll; }
  .shopify-buy__modal { margin: 100px auto 40px auto; opacity: 0; border-radius: 2px; border: 1px solid rgba(0, 0, 0, 0.72); -webkit-transform: translateY(-200px); transform: translateY(-200px); max-width: 1000px; -webkit-animation: 200ms slideOut forwards; animation: 200ms slideOut forwards; }
  .is-active .shopify-buy__modal { -webkit-animation-name: slideIn; animation-name: slideIn; }
  .shopify-buy__product { padding: 30px; }
  .shopify-buy__product-img-wrapper { height: 100%; padding-right: 30px; width: 100% !important; padding-top: 100% !important; position: relative !important; }
  .shopify-buy__product-img-wrapper .shopify-buy__product__variant-img { position: absolute !important; top: 0 !important; left: 0 !important; bottom: 0 !important; right: 0 !important; height: 100% !important; }
  .shopify-buy__product__variant-img { margin: 0 auto; }
  .shopify-buy__btn--close { top: -60px; color: white; }
  .shopify-buy__btn--close:hover { color: #fff; } }

@media (min-width: 680px) { .shopify-buy__product { padding: 45px; } }

@-webkit-keyframes flipIn { from { max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0; }
  to { max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1; } }

@keyframes flipIn { from { max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0; }
  to { max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1; } }

@-webkit-keyframes flipOut { from { max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1; }
  to { max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0; } }

@keyframes flipOut { from { max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1; }
  to { max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0; } }

.shopify-buy__cart-wrapper { height: 100%; padding-left: 10px; }

.shopify-buy__cart { height: 100%; background-color: #fff; width: calc(100% - 10px); position: absolute; right: 0; box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1); }

.shopify-buy__cart__header { padding: 20px; padding-right: 40px; position: relative; z-index: 2147483647; background-color: inherit; }

.shopify-buy__cart__title { font-size: 18px; color: #767676; font-weight: normal; overflow: hidden; text-overflow: ellipsis; }

.shopify-buy__cart-scroll { padding: 70px 0 135px 0; position: absolute; top: 0; height: 100%; width: 100%; }

.shopify-buy__cart-scroll--discounts { padding-bottom: 170px; }

.shopify-buy__cart-scroll--cart-note { padding-bottom: 200px; }

.shopify-buy__cart-scroll--cart-note.shopify-buy__cart-scroll--discounts { padding-bottom: 230px; }

.shopify-buy__cart-items { overflow: hidden; overflow-y: auto; height: 100%; position: relative; padding: 0 20px 20px; -webkit-overflow-scrolling: touch; -webkit-perspective: 400px; perspective: 400px; -webkit-perspective-origin: 50% 0px; perspective-origin: 50% 0px; }

.shopify-buy__cart-item { min-height: 65px; margin-bottom: 20px; overflow: hidden; position: relative; -webkit-backface-visibility: visible; backface-visibility: visible; -webkit-animation: 200ms flipIn forwards; animation: 200ms flipIn forwards; }

.shopify-buy__cart-item.is-hidden { -webkit-animation-name: flipOut; animation-name: flipOut; }

.shopify-buy__cart-item__image { width: 65px; height: 65px; background-size: contain; background-repeat: no-repeat; background-position: center center; background-color: transparent; position: absolute; left: 0; top: 0; }

.shopify-buy__cart-item__title { font-size: 14px; margin-left: 80px; margin-bottom: 3px; display: block; }

.shopify-buy__cart-item__price { float: right; font-size: 14px; font-weight: bold; line-height: 26px; }

.shopify-buy__cart-item__price-and-discounts { float: right; text-align: right; max-width: 100px; }

.shopify-buy__cart-item__price-and-discounts .shopify-buy__cart-item__price { float: none; }

.shopify-buy__cart-item__full-price { font-size: 12px; line-height: 12px; }

.shopify-buy__cart-item__discount { font-size: 12px; word-wrap: break-word; text-transform: uppercase; }

.shopify-buy__cart-item__discount + .shopify-buy__cart-item__discount { margin-top: 5px; }

.shopify-buy__cart-item__discount__icon { width: 12px; height: 12px; vertical-align: top; fill: currentColor; }

.shopify-buy__cart-item__variant-title { margin-left: 80px; margin-bottom: 10px; color: #4c4c4c; font-size: 12px; max-width: 220px; overflow: hidden; text-overflow: ellipsis; }

.shopify-buy__cart-bottom { background-color: #fff; position: absolute; width: 100%; bottom: 0; padding: 15px 20px 20px 20px; }

.shopify-buy__cart__subtotal__text { text-transform: uppercase; float: left; font-size: 11px; color: #4c4c4c; }

.shopify-buy__cart__subtotal__price { float: right; }

.shopify-buy__cart__discount { display: flex; margin-bottom: 10px; color: #4c4c4c; }

.shopify-buy__cart__discount__text { font-size: 11px; text-transform: uppercase; margin-right: 10px; flex-grow: 1; }

.shopify-buy__cart__discount__text__icon { width: 11px; height: 11px; vertical-align: top; fill: currentColor; }

.shopify-buy__cart__discount__amount { font-size: 12px; line-height: 12px; flex-shrink: 0; }

.shopify-buy__cart__currency { font-size: 12px; }

.shopify-buy__cart__notice { font-size: 11px; clear: both; padding-top: 10px; text-align: center; color: #4c4c4c; }

.shopify-buy__cart__note { clear: both; padding-top: 10px; }

.shopify-buy__cart__note__description { font-size: 11px; color: #4c4c4c; }

.shopify-buy__cart__note__text-area { resize: none; font-size: 11px; width: 100%; color: #4c4c4c; }

.shopify-buy__cart-empty-text { padding: 10px 15px; text-align: center; }

.shopify-buy__btn--cart-checkout { clear: both; margin-top: 15px; width: 100%; padding: 10px 5px; font-size: 16px; }

.shopify-buy__quantity-container { margin-left: 80px; margin-right: 100px; height: 26px; line-height: 26px; }

.shopify-buy__quantity-container.is-loading { opacity: 0.65; pointer-events: none; }

.shopify-buy__cart-item__quantity-input { float: left; background: transparent; }

@media (max-width: 330px) { .shopify-buy__cart-item__price-and-discounts { max-width: 90px; }
  .shopify-buy__quantity-container { margin-right: 90px; } }

.shopify-buy__cart-toggle-wrapper { display: inline-block; }

.shopify-buy__cart-toggle { background-color: #78b657; color: #fff; border-radius: 3px 0 0 3px; padding: 8px 10px; text-align: center; display: inline-block; min-width: 46px; margin-right: 0; cursor: pointer; transition: background 200ms ease; }

.shopify-buy__cart-toggle:hover { background-color: #5f9d3e; }

.shopify-buy__cart-toggle__count { font-size: 18px; margin-bottom: 10px; }

.shopify-buy__icon-cart__group { fill: #fff; }

.is-inline .shopify-buy__icon-cart, .is-inline .shopify-buy__cart-toggle__title, .is-inline .shopify-buy__cart-toggle__count { display: inline-block; vertical-align: middle; }

.is-inline .shopify-buy__icon-cart { margin-right: 5px; }

.is-inline .shopify-buy__cart-toggle__title { font-size: 16px; font-weight: normal; }

.is-inline .shopify-buy__cart-toggle__count { margin-left: 21px; margin-bottom: 0; position: relative; }

.is-inline .shopify-buy__cart-toggle__count:before { content: ""; display: block; position: absolute; left: -12px; height: 100%; width: 1px; background-color: #fff; opacity: 0.3; }

.is-inline.shopify-buy__cart-toggle { border-radius: 3px; padding: 5px 10px; }

.shopify-buy__collection { overflow: hidden; }

.shopify-buy__collection-products { margin-left: -15px; text-align: center; }

@media (min-width: 601px) { .shopify-buy__collection-products { margin-left: -20px; } }

.shopify-buy__product { min-width: 240px; width: auto; margin-left: 15px; display: inline-block; vertical-align: top; }

.shopify-buy__product + .shopify-buy__product { margin-top: 15px; }

@media (min-width: 601px) { .shopify-buy__product { width: calc(25% - 20px); margin-left: 20px; margin-bottom: 50px; }
  .shopify-buy__product + .shopify-buy__product { margin-top: 0; } }

.shopify-buy__btn.shopify-buy__collection-pagination-button { display: none; margin: 15px auto; }

.shopify-buy__btn.shopify-buy__collection-pagination-button.is-active { display: block; }

.shopify-buy-modal-is-active { height: 100%; overflow: auto; }

.shopify-buy-frame { display: inline-block; }

.shopify-buy-frame iframe { width: 100%; display: block; height: 0; overflow: hidden; }

.shopify-buy-frame--cart { width: 100%; max-width: 350px; position: fixed; top: 0; right: 0; height: 100%; z-index: 2147483647; transform: translateX(100%); -webkit-transform: translateX(100%); }

.shopify-buy-frame--cart iframe { height: 100%; }

.shopify-buy-frame--cart.is-initialized { transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1); }

.shopify-buy-frame--cart.is-active { transform: translateX(0); -webkit-transform: translateX(0); }

.shopify-buy-frame--product { display: block; }

.shopify-buy-frame--product.shopify-buy__layout-horizontal { display: block; margin-left: auto; margin-right: auto; }

.shopify-buy-frame--product.shopify-buy__layout-horizontal iframe { max-width: 100%; }

@media (min-width: 950px) { .shopify-buy-frame--product.shopify-buy__layout-horizontal iframe { max-width: 950px; margin-left: auto; margin-right: auto; } }

.shopify-buy-frame--toggle { display: inline-block; }

.shopify-buy-frame--toggle:not(.is-sticky) { overflow: hidden; padding: 5px; }

.shopify-buy-frame--toggle.is-sticky { display: none; position: fixed; right: 0; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); z-index: 2147483645; }

.shopify-buy-frame--toggle.is-active.is-sticky { display: block; }

.is-active .shopify-buy-frame--toggle iframe { min-height: 67px; }

.shopify-buy-frame--productSet { width: 100%; }

.shopify-buy-frame--modal { position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 2147483646; display: none; transition: background 300ms ease; }

.shopify-buy-frame--modal iframe { height: 100%; width: 100%; max-width: none; }

.shopify-buy-frame--modal.is-active { background: rgba(0, 0, 0, 0.6); }

.shopify-buy-frame--modal.is-block { display: block; }

.shopify-buy-frame--product .shopify-buy__product { padding: 0; }

.shopify-buy-frame--cart .shopify-buy__quantity { border-radius: 0; border-left: 0; border-left: 0; padding: 0; font-size: 16px; }

.shopify-buy-frame--cart .shopify-buy__quantity, .shopify-buy-frame--cart .shopify-buy__quantity-increment, .shopify-buy-frame--cart .shopify-buy__quantity-decrement { height: 30px; }

@media (max-width: 600px) { .shopify-buy-frame--modal .shopify-buy__product { width: 100%; padding: 30px; margin: 0; } }

@media (min-width: 601px) { .shopify-buy-frame--modal .shopify-buy__product { width: 100%; padding: 45px; margin: 0; } }

.store-item { max-width: 1400px; height: auto; }

.even { background-color: #000; }

.loading { height: 200px; display: flex; align-items: center; justify-content: center; }

.product { width: 100%; }

.shopify-buy__modal .shopify-buy__product .shopify-buy__product-img-wrapper .main-image-wrapper .shopify-buy__product__variant-img { max-width: 100%; }

.product .shopify-buy__product .shopify-buy__product-img-wrapper .main-image-wrapper { position: relative; width: 100%; height: 40vh; }

.product .shopify-buy__product .shopify-buy__product-img-wrapper .main-image-wrapper:after { content: ""; display: block; padding-bottom: 100%; }

.product .shopify-buy__product .shopify-buy__product-img-wrapper .main-image-wrapper img { position: absolute; top: 0; bottom: 0; left: 0; right: 0; padding-left: 5px; padding-right: 5px; margin-top: 5px; height: 100%; object-fit: cover; object-position: center; }

.even .product .shopify-buy__product .shopify-buy__product__title { color: #fff; }

.even .product .shopify-buy__product .shopify-buy__product__price .shopify-buy__product__actual-price { color: #fff; }

.even .product .shopify-buy__product .shopify-buy__btn-and-quantity .shopify-buy__btn { background-color: #fff; color: #000; }

.shopify-buy__btn-and-quantity { height: 45px; }

.shopify-buy__quantity-container { height: 100%; }

.shopify-buy__quantity, .shopify-buy__quantity-increment, .shopify-buy__quantity-decrement { padding: 0px; height: 100%; }

.even .product .shopify-buy__product .shopify-buy__product-description p { color: #fff; }

.even .product .shopify-buy__product .shopify-buy__product-description { color: #fff; }

@media (max-width: 499px) { .shopify-buy__product { position: relative; } }

.carousel-button { display: none; }

.shopify-buy__product { min-width: 0; width: 100%; padding: 0 !important; margin-left: 0 !important; }

.shopify-buy__product__title { padding-left: 5px; padding-right: 5px; padding-top: 10px; color: #000; text-align: center !important; font-weight: 0; }

@media screen and (max-width: 768px) { .shopify-buy__product__title { font-size: 1rem; } }

.shopify-buy__product__actual-price { color: #000; }

.shopify-buy__product-description { color: #000; }

.shopify-buy__product__price { color: #000; text-align: center !important; }

.shopify-buy__btn-wrapper { text-align: center !important; }

.shopify-buy__modal { width: calc(100% - 40px); height: calc(100% -160px); padding: 20px; top: 20; left: 20; background-color: #000; color: #fff; }

@media (max-width: 499px) { .shopify-buy__modal { height: calc(100% - 50px); min-height: calc(100% - 80px); }
  .shopify-buy__btn--close { right: 30px; } }

.shopify-buy__product-img-wrapper { padding: 0; height: auto; }

.shopify-buy__carousel { min-height: 30; margin-bottom: 15px; }

.lds-ellipsis { display: inline-block; position: relative; width: 80px; height: 80px; }

.lds-ellipsis div { position: absolute; top: 33px; width: 13px; height: 13px; border-radius: 50%; background: #000; -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0); animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) { left: 8px; -webkit-animation: lds-ellipsis1 0.6s infinite; animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) { left: 8px; -webkit-animation: lds-ellipsis2 0.6s infinite; animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) { left: 32px; -webkit-animation: lds-ellipsis2 0.6s infinite; animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) { left: 56px; -webkit-animation: lds-ellipsis3 0.6s infinite; animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 { 0% { -webkit-transform: scale(0); transform: scale(0); }
  100% { -webkit-transform: scale(1); transform: scale(1); } }

@keyframes lds-ellipsis1 { 0% { -webkit-transform: scale(0); transform: scale(0); }
  100% { -webkit-transform: scale(1); transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 { 0% { -webkit-transform: scale(1); transform: scale(1); }
  100% { -webkit-transform: scale(0); transform: scale(0); } }

@keyframes lds-ellipsis3 { 0% { -webkit-transform: scale(1); transform: scale(1); }
  100% { -webkit-transform: scale(0); transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 { 0% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
  100% { -webkit-transform: translate(24px, 0); transform: translate(24px, 0); } }

@keyframes lds-ellipsis2 { 0% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
  100% { -webkit-transform: translate(24px, 0); transform: translate(24px, 0); } }
/*# sourceMappingURL=main.css.map */
